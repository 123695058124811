<template>
  <b-row>
    <b-col>
      <b-card>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="35"
              height="35"
              viewBox="0 0 172 172"
              style=" fill:#000000; cursor: pointer"
              @click="$router.go(-1)"
            ><g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-size="none"
              style="mix-blend-mode: normal"
            ><path
              d="M0,172v-172h172v172z"
              fill="none"
            /><g fill="#08071a"><path d="M86,154.8c37.9948,0 68.8,-30.8052 68.8,-68.8c0,-37.9948 -30.8052,-68.8 -68.8,-68.8c-37.9948,0 -68.8,30.8052 -68.8,68.8c0,37.9948 30.8052,68.8 68.8,68.8zM53.27987,81.94653l22.93333,-22.93333c1.118,-1.118 2.58573,-1.67987 4.05347,-1.67987c1.46773,0 2.93547,0.56187 4.05347,1.67987c2.24173,2.24173 2.24173,5.8652 0,8.10693l-13.14653,13.14653h43.49307c3.1648,0 5.73333,2.5628 5.73333,5.73333c0,3.17053 -2.56853,5.73333 -5.73333,5.73333h-43.49307l13.14653,13.14653c2.24173,2.24173 2.24173,5.8652 0,8.10693c-2.24173,2.24173 -5.8652,2.24173 -8.10693,0l-22.93333,-22.93333c-2.24173,-2.24173 -2.24173,-5.8652 0,-8.10693z" /></g></g></svg>

            <div class="titulo-pagina ml-1">
              <div style="align-self: center">
                Cadastrar obra
              </div>
            </div>
          </div>
        </div>
        <b-row class="mt-2">
          <b-col>
            <b-form-group

              label="Nome da obra:"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="nome"
                placeholder="Nome"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione o cliente :"
              label-for="cliente"
            >
              <v-select
                v-model="cliente"
                label="nome"
                placeholder="Cliente"
                :options="opcoescliente"
                @input="BuscarOpcoesContrato"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione o Contrato :"
              label-for="contrato"
            >
              <v-select
                v-model="contrato"
                label="numero_contrato"
                placeholder="Contrato"
                :options="opcoescontrato"
                :disabled="!cliente"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Selecione a Disciplina :"
              label-for="disciplina"
            >
              <v-select
                v-model="disciplina"
                label="nome"
                placeholder="disciplina"
                :options="opcoesdisciplina"
                @input="buscarAtividade"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Selecione a Atividade :"
              label-for="atividade"
            >
              <v-select
                v-model="atividade"
                label="nome"
                placeholder="atividade"
                :options="opcoesatividade"
                :disabled="!disciplina"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group

              label="Descrição (opcional):"
              label-for="descricao"
            >
              <b-form-textarea
                id="descricao"
                v-model="descricao"
                placeholder="Descrição"
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <uploader
              title="Adicione imagens da obra"
              name="upload"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col>
            <div style="display: flex">
              <b-button
                class="px-3"
                variant="primary"
                @click="SalvarObra()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                Salvar Obra
              </b-button>
              <div
                class="ml-2"
                style="cursor: pointer; align-self: center"
                variant="primary"
                @click="LimparCampos()"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                Limpar Campos
              </div>
            </div>
          </b-col>

        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Uploader from 'vux-uploader-component'

export default {
  name: 'IndexObra',
  components: {
    BRow,
    Uploader,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      url: 'admin/obra',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: null,
      contrato: null,
      opcoescontrato: [],
      nome: null,
      descricao: null,
      cliente: null,
      opcoescliente: [],
      disciplina: '',
      opcoesdisciplina: [],
      atividade: '',
      opcoesatividade: [],
    }
  },
  watch: {
    cliente() {
      this.contrato = null
    },
  },
  async created() {
    if (this.$route.name === 'adm-obra-ver') {
      this.BuscarDados()
      await this.$http.get('admin/clientes_ver').then(cliente => {
        this.opcoescliente = cliente.data
      })
      await this.$http.get('admin/disciplina_categoria').then(disciplina => {
        this.opcoesdisciplina = disciplina.data.data
      })
    }
  },
  methods: {
    async BuscarDados() {
      this.$loading(true)
      this.edicao = JSON.parse(localStorage.getItem('admin/edicao'))
      if (this.edicao) {
        this.obraeditar = JSON.parse(localStorage.getItem('admin/edicao'))
        this.id = this.obraeditar.id
        this.nome = this.obraeditar.nome
        this.descricao = this.obraeditar.descricao
        await this.$http.get(`admin/cliente_buscar/${this.obraeditar.cliente_id}`).then(clientes => {
          this.cliente = clientes.data
        })
        await this.$http.get(`admin/contrato_buscar/${this.obraeditar.contrato_id}`).then(contratos => {
          this.contrato = contratos.data
        })
        await this.$http.get(`admin/buscar_disciplina/${this.obraeditar.id}`).then(disciplinas => {
          this.disciplina = disciplinas.data
        })
        await this.$http.get(`admin/buscar_atividade/${this.obraeditar.id}`).then(atividade => {
          this.atividade = atividade.data
        })
        this.$loading(false)
      }
      if (!this.edicao) {
        this.contratos = JSON.parse(localStorage.getItem('admin/contratos'))
        await this.$http.get(`admin/cliente_buscar/${this.contratos.data.contrato.cliente_id}`)
          .then(clientes => {
            this.cliente = clientes.data
          })
        this.contrato = this.contratos.data.contrato
        this.$loading(false)
      }
    },
    async buscarAtividade() {
      this.atividade = ''
      if (this.disciplina === null) return
      await this.$http.get(`admin/atividade_ver/${this.disciplina.id}`).then(atividade => {
        this.opcoesatividade = atividade.data
        this.atividade = ''
      })
    },
    async BuscarOpcoesContrato() {
      if (this.cliente === null) return
      await this.$http.get(`admin/contrato_ver/${this.cliente.value}`).then(contrato => {
        this.opcoescontrato = contrato.data
      })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    LimparCampos() {
      this.id = null
      this.contrato = null
      this.nome = null
      this.descricao = null
      this.cliente = null
      this.disciplina = null
      this.atividade = null
    },
    SalvarObra() {
      const obj = {
        contrato_id: this.contrato.id,
        nome: this.nome,
        descricao: this.descricao,
        cliente_id: this.cliente.id,
        disciplina_id: this.disciplina.id,
        atividade_id: this.atividade.value,
      }
      this.$loading(true)
      if (this.id === null) {
        this.$http.post('admin/obra', obj).then(resp => {
          localStorage.setItem('admin/obras', JSON.stringify(resp))
          this.$loading(false)
          this.$router.push({
            name: 'adm-descricao-ver',
            params: {
              id: resp.data.obra.id,
            },
          })
        })
      }
      if (this.id) {
        this.$http.put(`admin/obra/${this.id}`, obj).then(resp => {
          if (resp.data.success) {
            this.Notificacao('success', resp.data.success)
            this.$loading(false)
            this.$router.push({
              name: 'adm-obra',
            })
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
            this.$loading(false)
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
